import { Observable, from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { switchMap, filter, map, catchError } from 'rxjs/operators';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import {
  AddedDataAction,
  AddDataAction,
  ModifyDataAction,
  DeleteDataAction,
  UpsertDataAction
  // UpsertedDataAction
} from './firestore.actions';
import {
  allFirestoreAddEffectTypes,
  allFirestoreModifyEffectTypes,
  allFirestoreActionsToReducers,
  allFirestoreDeleteEffectTypes,
  allFirestoreUpsertEffectTypes
} from './firestore.effects.types';
import { BaseModel } from '@core/models/basemodel';
import { FirestoreReducer, FirestoreReducerDataState } from './firestore.reducer';

/**Class created to handle asynchronous firestore related requests */

@Injectable()
export class FirebaseEffects {
  constructor(private actions$: Actions, private db: AngularFirestore) {}

  @Effect({ dispatch: false })
  upsertModel$ = this.actions$.pipe(
    filter(action => allFirestoreUpsertEffectTypes.includes(action.type)),
    switchMap((action: UpsertDataAction) => {
      const data = { ...action.data };
      const id = data.id;
      const requestType = action.type;
      const reducer = allFirestoreActionsToReducers[requestType];
      const modelIdName = reducer.INNER_COLLECTION_ID;
      const collection = reducer.getCollectionReference(this.db, {});

      reducer.modifyModelDataBeforeWriting(data);

      data.lastModified = new Date();

      const doc = collection.doc(id);
      console.log(`En upsert`, data);
      const modifyPromise = doc
        .set(data, { merge: true })
        .then(
          () =>
            //Obtain document snapshot to allow setting document id inside data
            doc
              .get()
              .pipe(map(documentSnapshot => documentSnapshot))
              .toPromise()
          // TODO: Notifiy to store: action done!
        )
        .then(this.addFirestoreIdToDocument(reducer, modelIdName));

      return from(modifyPromise);
    }),
    catchError(error => {
      console.log(`Error in upsertModel`, error);
      return of('Hola');
    })
  );

  /**Keeps listening for an action to create a firestore model and does everything needed before calling ACTION_ADDED with the recently created document */
  @Effect({ dispatch: false })
  addModel$ = this.actions$.pipe(
    filter(action => allFirestoreAddEffectTypes.includes(action.type)),
    switchMap(
      (action: AddDataAction): Observable<AddedDataAction> => {
        const data = { ...action.data };
        const requestType = action.type;
        const reducer = allFirestoreActionsToReducers[requestType];
        const modelIdName = reducer.INNER_COLLECTION_ID;
        const collection = reducer.getCollectionReference(this.db, {});

        data.lastModified = new Date();

        const addPromise = collection
          .add(data as object)
          .then((model: DocumentReference) => model.get())
          .then(this.addFirestoreIdToDocument(reducer, modelIdName));

        return from(addPromise);
      }
    ),
    catchError(error => {
      console.log(`Error in addModel`, error);
      return of('Hola');
    })
  );

  @Effect({ dispatch: false })
  modifyModel$ = this.actions$.pipe(
    filter(action => allFirestoreModifyEffectTypes.includes(action.type)),
    switchMap((action: ModifyDataAction) => {
      const data = { ...action.data };
      const id = data.id;
      const requestType = action.type;
      const reducer = allFirestoreActionsToReducers[requestType];
      const collection = reducer.getCollectionReference(this.db, {});

      data.lastModified = new Date();

      reducer.modifyModelDataBeforeWriting(data);

      const modifyPromise = collection
        .doc(id)
        .set(data, { merge: true })
        .then();

      return from(modifyPromise);
    }),
    catchError(error => {
      console.log(`Error in modifyModel`, error);
      return of('Hola');
    })
  );

  @Effect({ dispatch: false })
  deleteModel$ = this.actions$.pipe(
    filter(action => allFirestoreDeleteEffectTypes.includes(action.type)),
    switchMap((action: DeleteDataAction) => {
      const id = action.id;
      const requestType = action.type;
      const reducer = allFirestoreActionsToReducers[requestType];
      const collection = reducer.getCollectionReference(this.db, {});

      const modifyPromise = collection
        .doc(id)
        .delete()
        .then();

      return from(modifyPromise);
    }),
    catchError(error => {
      console.log(`Error in deleteModel`, error);
      return of('Hola');
    })
  );

  //In charge of setting document id inside document data usind a DocumentSnapshot
  addFirestoreIdToDocument = function(
    reducer: FirestoreReducer<BaseModel, FirestoreReducerDataState<BaseModel>>,
    modelIdName: string
  ) {
    // this.store.dispatch(new UpsertedDataAction('',id,data));
    return createdDocument => {
      const data = { ...createdDocument.data() };
      if (reducer.AUTO_SET_INNER_COLLECTION_ID) {
        const id = createdDocument.id;
        data[modelIdName] = id;
        return createdDocument.ref.set(data, { merge: true }).then(() => data);
      }

      return data;
    };
  };
}
