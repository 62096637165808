import { User } from '@models/user';
import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { FIRESTORE_COLLECTION_USERS } from '@store/firestore/allFirestoreCollections';
import { userDataState } from '@store/users';
import { createSelector } from '@ngrx/store';
import { FirestoreCollectionReducer } from '@store/firestore/firestore-collection.reducer';

export interface UserDataState extends FirestoreReducerDataState<User> {}

export class UserReducer extends FirestoreCollectionReducer<User, UserDataState> {
  private static instance: UserReducer;

  featureSelector = userDataState;
  collectionName = FIRESTORE_COLLECTION_USERS;

  INNER_COLLECTION_ID = 'userId';

  ADD_ACTION = '[User] Add user';
  UPSERT_ACTION = '[User] Upsert user';
  DELETE_ACTION = '[User] Delete user';
  MODIFY_ACTION = '[User] Modify user';

  ADDED_ACTION = '[User] Added user from firebase';
  DELETED_ACTION = '[User] Deleted user from firebase';
  MODIFIED_ACTION = '[User] Modified user from firebase';
  UPSERTED_ACTION = '[User] Upserted user from firebase';
  MODIFIED_LOCALLY_ACTION = '[User] Modify locally user';

  static getInstance() {
    if (!UserReducer.instance) {
      UserReducer.instance = new UserReducer();
    }
    return UserReducer.instance;
  }
}

export const activeUsersSelector = createSelector(UserReducer.getInstance().getConvertToArraySelector(), users => {
  return users.filter(user => {
    return user.isActive;
  });
});

export const activeUsersCountSelector = createSelector(activeUsersSelector, activeUsers => {
  return activeUsers.length;
});
