export enum ShowDetailActionsType {
  LOAD_SHOW = '[ShowDetail] Load show',
  CLEAR_DATA = '[ShowDetail] Clear data',
  SHOW_LOADED = '[ShowDetail] Show loaded',
  LOAD_REGISTRANTS = '[ShowDetail] Load registrants',
  REVENUES_UPDATED = '[ShowDetail] Revenues updated',
  ATTENDEES_UPDATED = '[ShowDetail] Attendees updated',
  FILTER_REGISTRANTS = '[ShowDetail] Filter registrants',
  SEARCH_REGISTRANTS = '[ShowDetail] Search registrants',
  REGISTRANTS_LOADED = '[ShowDetail] Registrants loaded',
  REGISTRANTS_UPDATED = '[ShowDetail] Registrants updated',
  LOAD_SHOW_AGGREGATED_DATA = '[ShowDetail] Load show aggregated data',
  SELECT_ALL_REGISTRANTS_IN_TAB = '[ShowDetail] Select all registrants in tab'
}
