import { map, switchMap } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseModel } from '@models/basemodel';
import { Query, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})

/**Class made to automatize handling of Query, Collections and Document snapshots so to avoid duplicating id in documents */
export class FirebaseUtilitiesService {
  /**Extracts id from a query snapshot and rebuilds document data with id, parsing it as an array of the passed Model Class */
  dataQuery<T extends BaseModel>(query: Query): Observable<T[]> {
    const queryPromise = query.get().then(querySnapshot => {
      return querySnapshot.docs.map(doc => {
        const id = doc.id;
        const data = doc.data() as T;
        data.id = id;
        return data;
      });
    });

    return from<T[]>(queryPromise);
  }

  /**Extracts id from a collection snapshot and rebuilds document data with id, parsing it as an array of the passed Model Class */
  dataCollection<T extends BaseModel>(collection: AngularFirestoreCollection<T>): Observable<T[]> {
    return collection.snapshotChanges().pipe(
      map(actions =>
        actions.map(action => {
          const doc = action.payload.doc;
          const data = doc.data() as T;
          const id = doc.id;
          data.id = id;
          return data;
        })
      )
    );
  }

  /**Extracts id from a document snapshot and rebuilds document data with id, parsing it as the passed Model Class. Keeps listening for changes */
  snapshotChangesToData<T extends BaseModel>(document: AngularFirestoreDocument<T | null>): Observable<T> {
    return document.snapshotChanges().pipe(switchMap(innerDoc => this.dataFromDocSnapshot(innerDoc.payload)));
  }

  /**Extracts id from a document snapshot and rebuilds document data with id, parsing it as the passed Model Class only once*/
  getToData<T extends BaseModel>(document: AngularFirestoreDocument<T | null>): Observable<T> {
    return document.get().pipe(switchMap(innerDoc => this.dataFromDocSnapshot(innerDoc)));
  }

  dataFromDocSnapshot<T extends BaseModel>(document: firebase.firestore.DocumentSnapshot): Observable<T> {
    if (!document.exists) {
      return of(null);
    }

    const data = document.data() as T;

    const id = document.id;
    data.id = id;

    return of(data);
  }
}
