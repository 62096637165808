import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { RegistrantReducer } from './registrant.firestore.reducer';
import { tap } from 'rxjs/operators';
import { AddedDataAction } from '@store/firestore/firestore.actions';
import { FirestoreReduxService } from '@services/firebase/firestore-redux.service';
import { Action } from '@ngrx/store';
import { buildParentPath } from '@store/firestore/firestore-subcollection-effect-helper';

@Injectable()
export class RegistrantsEffects {
  private reducer = RegistrantReducer.getInstance();

  constructor(private actions$: Actions, private firestoreReduxService: FirestoreReduxService) {}

  @Effect({ dispatch: false })
  registrantAdded$: Observable<Action> = this.actions$.pipe(
    ofType(this.reducer.ADDED_ACTION),
    tap((action: AddedDataAction) => {
      const parentRoute = buildParentPath(action.ancestorsId, this.reducer.collectionName, action.id);
      this.firestoreReduxService.startRegistrantEventMailListFirestoreReduxListener(parentRoute, []);
    })
  );
}
