import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action, select, MemoizedSelector } from '@ngrx/store';
import { AppState } from '@store';
import { RegistrantReducer } from '@store/registrants/registrant.firestore.reducer';
import { ShowReducer } from '@store/shows/show.firestore.reducer';
import { Observable } from 'rxjs';
import { ShowDetailActionsType } from '@store/show-detail/show-detail.actions.name';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';
import {
  ShowDetailLoadShow,
  ShowDetailShowLoaded,
  ShowDetailRegistrantsLoaded,
  ShowDetailSearchRegistrants,
  ShowDetailFilterRegistrant,
  FILTER_TICKET_ASSIGNED,
  FILTER_ATTENDEES,
  FILTER_INVITED,
  FILTER_NOT_GOING,
  ShowDetailSelectAllRegistrantsInTab
} from '@store/show-detail/show-detail.actions';
import { showDetailDataState } from '.';
import { Registrant } from '@core/models/registrant';

@Injectable()
export class ShowDetailEffects {
  private showReducer: ShowReducer;
  private regReducer: RegistrantReducer;
  private actualFilter: MemoizedSelector<object, Registrant[]>;

  constructor(private actions$: Actions, private store: Store<AppState>) {
    this.showReducer = ShowReducer.getInstance();
    this.regReducer = RegistrantReducer.getInstance();
  }

  @Effect()
  loadShow$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.LOAD_SHOW),
    switchMap((action: ShowDetailLoadShow) => {
      const showId = action.showId;
      this.actualFilter = this.regReducer.getInvitedByShowIdSelector(showId);
      const selector = this.showReducer.getEntityByIdSelector(showId);
      return this.store.pipe(
        select(selector),
        map(show => new ShowDetailShowLoaded(show))
      );
    })
  );

  @Effect()
  loadRegistrants$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.LOAD_REGISTRANTS),
    switchMap((action: ShowDetailLoadShow) => {
      const showId = action.showId;
      const selector = this.regReducer.getInvitedByShowIdSelector(showId);
      return this.store.pipe(
        select(selector),
        map(registrants => new ShowDetailRegistrantsLoaded(registrants))
      );
    })
  );

  @Effect()
  searchRegistrants$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.SEARCH_REGISTRANTS),
    switchMap((action: ShowDetailSearchRegistrants) => {
      const text = action.text;
      let selector = this.regReducer.getRegistrantsByShowIdSearchSelector(text, this.actualFilter);
      if (text.trim().length === 0) {
        selector = this.actualFilter;
      }

      return this.store.pipe(
        select(selector),
        map(registrants => new ShowDetailRegistrantsLoaded(registrants))
      );
    })
  );

  @Effect()
  showFilter$: Observable<Action> = this.actions$.pipe(
    ofType(ShowDetailActionsType.FILTER_REGISTRANTS),
    withLatestFrom(this.store.pipe(select(showDetailDataState))),
    switchMap(([action, { search }]) => {
      const showDetailAction = action as ShowDetailFilterRegistrant;

      const showId = showDetailAction.showId;
      const filter = showDetailAction.filter;
      switch (filter) {
        case FILTER_INVITED:
          this.actualFilter = this.regReducer.getInvitedByShowIdSelector(showId);
          break;
        case FILTER_NOT_GOING:
          this.actualFilter = this.regReducer.getNotGoingByShowIdSelector(showId);
          break;
        case FILTER_TICKET_ASSIGNED:
          this.actualFilter = this.regReducer.getTicketAssignedByShowIdSelector(showId);
          break;
        case FILTER_ATTENDEES:
          this.actualFilter = this.regReducer.getAttendeesByShowIdSelector(showId);
          break;
        default:
          this.actualFilter = this.regReducer.getRegistrantsByShowIdSelector(showId);
          break;
      }

      return [new ShowDetailSelectAllRegistrantsInTab(), new ShowDetailSearchRegistrants(showId, search)];
    })
  );
}
