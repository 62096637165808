import * as firebase from 'firebase/app';
import { Injectable } from '@angular/core';
import { switchMap, map, distinctUntilChanged, catchError } from 'rxjs/operators';
import { Observable, of, from, Subject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthServiceErrors } from './auth.service.errors';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { environment } from '@env';
import { User } from '@models/user';
import { FirebaseUtilitiesService } from '@services/firebase/firebase-utilities.service';
import {
  FIRESTORE_COLLECTION_USERS,
  FIRESTORE_COLLECTION_COMPANIES
} from '../../store/firestore/allFirestoreCollections';
import { Company } from '@core/models/company';
import { ApiService } from '@services/firebase/api.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Service used to do any authentication including login, signup, logged in validation and logout
 */
export class AuthService {
  /**Full name received from register form to be set as company name */
  private fullName = '';
  /** Firebase Authentication user, used to create woden users in @user$ while keeping firebase uid */
  private firebaseAuthUser: firebase.User;
  /** Firestore auth instance to invite users. Done this way to prevent invited user auto login when account is created  */
  /** Preferred this way instead of using  Firebase Admin API to avoid using another server*/
  private usersInviteAuth: firebase.auth.Auth;
  /** Woden users collection */
  private usersCollection: AngularFirestoreCollection<User> = this.db.collection(FIRESTORE_COLLECTION_USERS);
  /** Woden companies collection */
  private companiesCollection: AngularFirestoreCollection<Company> = this.db.collection(FIRESTORE_COLLECTION_COMPANIES);
  /** Observable used to get actually logged in user id. This is the one in charge of requesting logged in user data from firestore using loadUser() */
  private userIdSubject: Subject<string> = new Subject();

  constructor(
    private db: AngularFirestore,
    private apiService: ApiService,
    private fireAuth: AngularFireAuth,
    private fireUtilities: FirebaseUtilitiesService
  ) {
    /** User is only logged in while tab is open (during actual session) */
    const keepInSession = firebase.auth.Auth.Persistence.SESSION;
    firebase
      .auth()
      .setPersistence(keepInSession)
      .then();

    this.fireAuth.authState.pipe(distinctUntilChanged()).subscribe(firebaseAuthUser => {
      this.firebaseAuthUser = firebaseAuthUser;
      if (!this.firebaseAuthUser) {
        /** No user could be retrieved from firebase authentication */
        this.newAuthUserRequested(false);
        return;
      }

      /** Request firebase authenticated user from remote database */
      const userDocument = this.usersCollection.doc<User>(this.firebaseAuthUser.uid);
      this.fireUtilities.getToData<User>(userDocument).subscribe(user => this.newAuthUserRequested(!!user));
    });
  }

  private authPromise(authPromise: Promise<firebase.auth.UserCredential>): Observable<User> {
    const builtPromise = authPromise
      .then(_ => {
        return this.loadUser();
      })
      .catch(error => {
        throw this.handleError(error.code);
      });

    return from(builtPromise).pipe(switchMap(user => user));
  }

  private getUsersInviteAuth(): firebase.auth.Auth {
    if (!this.usersInviteAuth) {
      const usersInviteAuthApp = firebase.initializeApp(environment.firebase, 'innerApp');
      this.usersInviteAuth = usersInviteAuthApp.auth();
    }
    return this.usersInviteAuth;
  }

  private handleError(errorCode: string): Error {
    switch (errorCode) {
      case 'auth/wrong-password':
        return new Error(AuthServiceErrors.WRONG_PASWORD);
      case 'auth/user-not-found':
        return new Error(AuthServiceErrors.USER_NOT_FOUND);
      case 'auth/invalid-email':
        return new Error(AuthServiceErrors.INVALID_EMAIL);
      case 'auth/popup-closed-by-user':
        return new Error(AuthServiceErrors.POP_UP_CLOSED_BY_USER);
      case 'auth/email-already-in-use':
        return new Error(AuthServiceErrors.EMAIL_ALREADY_IN_USE);
      case 'auth/weak-password':
        return new Error(AuthServiceErrors.INVALID_PASSWORD);
      default:
        return new Error(AuthServiceErrors.UNKNOWN_ERROR);
    }
  }

  /** Creates an user and its company when it is successfully retrieved from firebase authentication but it doesn't exist in remote database i.e when the user registers for the first time*/
  private async createUserAndCompanyFromFirebase(): Promise<boolean> {
    const firebaseUser = this.firebaseAuthUser;

    const userId: string = firebaseUser.uid;
    const email: string = firebaseUser.email;

    const user: User = {
      userId,
      email: email,
      isActive: true,
      isSuperUser: true,
      companyId: userId,
      changePassword: false
    };

    const company: Company = {
      planId: '',
      isActive: true,
      companyId: userId,
      name: this.fullName,
      preferences: {
        Pin: '',
        bbcMail: email,
        imageProfile: '',
        EmailCopyHidden: email
      }
    };

    /**If user registers using Google account, no fullName would be provided */
    if (!this.fullName) {
      let name = firebaseUser.displayName; /**Try to use firebase auth user displayName or email */
      if (!name || name.length === 0) {
        name = email;
      }
      company.name = name;
    }

    if (firebaseUser.photoURL) {
      company.preferences['imageProfile'] = firebaseUser.photoURL;
    }

    const userDoc = this.usersCollection.doc(userId);
    const companyDoc = this.companiesCollection.doc(userId);

    try {
      await userDoc.set(user, { merge: true });

      await companyDoc.set(company, { merge: true });

      return true;
    } catch (error) {
      console.log(`Error creando usuario y compañía`, error);
      try {
        await userDoc.delete();
      } catch (error) {
        console.log(`Error deleting user`, error);
      }

      try {
        await companyDoc.delete();
      } catch (error) {
        console.log(`Error deleting company`, error);
      }
      return false;
    }
  }

  private newAuthUserRequested(exists: boolean) {
    /** If user is successfully retrieved from firebase authentication but it doesn't exist in remote database
     * it's because it's being registered via oauth login (first login)
     */
    const userId = this.firebaseAuthUser ? this.firebaseAuthUser.uid : undefined;

    //If doesn't need to create user and company
    if (!this.firebaseAuthUser || exists) {
      this.userIdSubject.next(userId);
      return;
    }

    //Make a last try to retrieve woden user
    this.usersCollection
      .doc(userId)
      .get()
      .pipe(
        map(async docRef => {
          if (docRef.exists) {
            this.userIdSubject.next(userId);
            return;
          }

          try {
            const created = await this.createUserAndCompanyFromFirebase();
            if (created) {
              this.userIdSubject.next(userId);
            } else {
              console.log(`User and company not created`);
            }
          } catch (error) {
            console.log(`User and company error`, error);
          }
        })
      )
      .subscribe();
  }

  inviteUser(email: string, companyId: string, superUserId: string): Observable<object> {
    const password = Math.random()
      .toString(36)
      .slice(2);

    const newUser = {
      email,
      superUserId,
      isActive: false,
      isSuperUser: false,
      companyId: companyId,
      changePassword: true
    };

    const usersInviteAuth = this.getUsersInviteAuth();

    const userPromise = usersInviteAuth
      .createUserWithEmailAndPassword(email, password)
      .then(userCredentials => {
        const userId = userCredentials.user.uid;

        newUser['userId'] = userId;
        return this.usersCollection.doc(userId).set(newUser, { merge: true });
      })
      .catch(error => {
        throw this.handleError(error.code);
      });

    return from(userPromise).pipe(switchMap(_ => this.apiService.sendInvitationEmail(email, password)));
  }

  register(email: string, password: string, fullName: string): Observable<User> {
    this.fullName = fullName;
    const registerPromise = this.fireAuth.auth.createUserWithEmailAndPassword(email, password);

    return this.authPromise(registerPromise); //false;
  }

  loadUser(): Observable<User> {
    return this.userIdSubject.pipe(
      distinctUntilChanged(),
      switchMap(userId => {
        if (userId) {
          return this.fireUtilities.snapshotChangesToData(this.usersCollection.doc(userId));
        } else {
          return of(null);
        }
      }),
      catchError(error => {
        console.log(`Error in loadUser`, error);
        return of(null);
      })
    );
  }

  loginWithEmailAndPassword(email: string, password: string): Observable<User> {
    this.fullName = '';
    const loginPromise = this.fireAuth.auth.signInWithEmailAndPassword(email, password);

    return this.authPromise(loginPromise);
  }

  changePassword(password: string): Observable<void> {
    this.fullName = '';
    const changePasswordPromise = this.fireAuth.auth.currentUser.updatePassword(password);

    return from(changePasswordPromise);
  }

  loginWithGoogle(): Observable<User> {
    this.fullName = '';
    const provider = new firebase.auth.GoogleAuthProvider();
    const googleLoginPromise = this.fireAuth.auth.signInWithPopup(provider);

    return this.authPromise(googleLoginPromise);
  }

  forgotPassword(email: string): Observable<boolean> {
    const forgotPromise = this.fireAuth.auth
      .sendPasswordResetEmail(email)
      .then(() => true)
      .catch(error => {
        throw this.handleError(error.code);
      });

    return from(forgotPromise);
  }

  logout(): Promise<void> {
    return this.fireAuth.auth.signOut();
  }
}
