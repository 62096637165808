import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserInvitationEmail } from '@core/models/email-user-invitation';
import { userInvitationEmail } from '@shared/emails/user-invitation';
import { EventEmail } from '@core/models/email-event';
import { RSVPEmail } from '@core/models/email-rsvp';
import { WodenDate } from '@core/models/woden-date';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private HEADER_APP_JSON = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

  sendRSVPEmail(email: RSVPEmail): Observable<object> {
    return this.http.post(environment.api.rsvpEmailUrl, email, { headers: this.HEADER_APP_JSON });
  }

  sendEventEmail(email: EventEmail): Observable<object> {
    return this.http.post(environment.api.emailUrl, email, { headers: this.HEADER_APP_JSON });
  }

  sendInvitationEmail(email: string, password: string): Observable<object> {
    const html = userInvitationEmail;
    const emailToSend: UserInvitationEmail = {
      html,
      to: email,
      from: 'info@wodenevents.com',
      bcc: '',
      registrants: [],
      subject: 'Invitation',
      password,
      userName: email
    };

    return this.http.post(environment.api.invitationEmailUrl, emailToSend, { headers: this.HEADER_APP_JSON });
  }

  getLocationUTCOffset(lat: number, lng: number, dateInPCTime: Date): Observable<object> {
    const date = WodenDate.cloneDate(dateInPCTime, true);
    const timestamp = date.getTime();
    const params = {};
    params['lat'] = lat;
    params['lng'] = lng;
    params['timestamp'] = timestamp;
    return this.http.get(environment.api.getLocationOffsetUrl, { headers: this.HEADER_APP_JSON, params });
  }
}
