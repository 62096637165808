// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const cloudFunctionsUrl = 'https://us-central1-woden-dev.cloudfunctions.net';
const apiUrl = cloudFunctionsUrl + '/api';
const apiAuthUrl = apiUrl + '/auth';
const apiEmailUrl = apiUrl + '/emails';
const wodenUrl = 'https://dev.wodenevents.com';

export const environment = {
  production: false,

  wodenUrl: wodenUrl,

  rsvpNotGoing: wodenUrl + '/emailRSVPResponse?newState=notGoing',
  rsvpAcceptFree: wodenUrl + '/emailRSVPResponse?newState=registered',

  gMapsApiKey: 'AIzaSyCq6HXCNZMny39Vl6DYpODPTElkvt6S1OM&',

  stripeApiKey: 'pk_test_dTD2BCKFinXdc6def6mtQ77n00orAqccYQ',

  api: {
    emailUrl: apiEmailUrl + '/show',
    rsvpEmailUrl: apiEmailUrl + '/rsvp',
    createTokenUrl: apiAuthUrl + '/createToken',
    invitationEmailUrl: apiEmailUrl + '/invitation',
    generateAPIKeyUrl: apiAuthUrl + '/generateAPIKey',
    getLocationOffsetUrl: apiUrl + '/utc/locationOffset',
    addRegistrantsListUrl: apiUrl + '/registrantLists/events/:showId'
  },

  /**Firebase for Woden development */
  firebase: {
    apiKey: 'AIzaSyBsgjucL59raMdYfruI2P1NZs5v0DaUuR0',
    authDomain: 'woden-dev.firebaseapp.com',
    databaseURL: 'https://woden-dev.firebaseio.com',
    projectId: 'woden-dev',
    storageBucket: 'woden-dev.appspot.com',
    messagingSenderId: '158024523272'
  }

  /**Firebase for testing purposes */
  // firebase: {
  //   apiKey: "AIzaSyDrwNl5dKjARvn0VdNqho_lPB5sIxas2mU",
  //   authDomain: "fir-testing-c6c46.firebaseapp.com",
  //   databaseURL: "https://fir-testing-c6c46.firebaseio.com",
  //   projectId: "fir-testing-c6c46",
  //   storageBucket: "fir-testing-c6c46.appspot.com",
  //   messagingSenderId: "612253561892"
  // }
};