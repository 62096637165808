export enum DashboardActionsType {
  SHOWS_SEARCH = '[Dashboard] Shows search',
  SHOWS_FILTER = '[Dashboard] Shows filter',
  SHOW_SELECTED = '[Dashboard] Show selected',
  SHOWS_UPDATED = '[Dashboard] Shows updated',
  ALL_SHOWS_DETAIL = '[Dashboard] All shows detail',
  REVENUES_UPDATED = '[Dashboard] Revenues updated',
  ATTENDEES_UPDATED = '[Dashboard] Attendees updated',
  SHOWS_PAGE_CHANGED = '[Dashboard] Shows page changed',
  REGISTRANTS_UPDATED = '[Dashboard] Registrants updated'
}
