import { BaseModel } from '../../core/models/basemodel';
import { FirestoreAction } from './firestore.actions';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { FirestoreCollectionReference } from '@core/models/firestore-collection-reference';

interface Entities<T> {
  [key: string]: T;
}
/**Interface describing the base Firebase Reducer Data State that will contain all the received
 *objects of the reducer type, filters, and each implementation can add here its custom values to be stored */
export interface FirestoreReducerDataState<T extends BaseModel> {
  /**Base state format is a key with the collection name and an object with the format model.id: model
   * so we can access any document by its id
   */
  [models: string]: Entities<T>;
  filters: {};
}

/**Abstract class modeling Firestore Reducers, in charge of handling our custom
 * actions when Firestore Redux actions are received by FirestoreReduxListener or
 * any other action related to our Firebase implementations*/

export abstract class FirestoreReducer<T extends BaseModel, DataState extends FirestoreReducerDataState<T>> {
  abstract collectionName: string;
  abstract featureSelector: MemoizedSelector<object, DataState>;

  /**Used to check if must set document id inside document data in firestore */
  AUTO_SET_INNER_COLLECTION_ID = true;
  /**This is the collection inner id to be used if @AUTO_SET_INNER_COLLECTION_ID is true */
  INNER_COLLECTION_ID = '';

  /**Custom keys to allow multiple FirestoreReducers to extend this class while having their
   * custom keys for the actions */
  /**-------------------------------------- */

  abstract ADD_ACTION: string;
  abstract UPSERT_ACTION: string;
  abstract DELETE_ACTION: string;
  abstract MODIFY_ACTION: string;

  abstract ADDED_ACTION: string;
  abstract DELETED_ACTION: string;
  abstract MODIFIED_ACTION: string;
  abstract UPSERTED_ACTION: string;
  //Used to process a local only modifictaion
  abstract MODIFIED_LOCALLY_ACTION: string;

  /**-------------------------------------- */

  /**Initial Reducer state. Must be initialized externally because of Typescript inheritance implementation */
  initialState: DataState;

  /**Object to store dinamically created selectors to keep memoization work */
  private storedSelectors: { [id: string]: MemoizedSelector<object, any> } = {};
  /**Selector to get all stored documents of type T */
  private allEntitiesSelector: MemoizedSelector<object, Entities<T>>;
  /**Selector to convert all saved documents to an array */
  private convertToArraySelector: MemoizedSelector<object, T[]>;
  /**Selector to count all saved documents */
  private countSelector: MemoizedSelector<object, number>;

  /** Any modifications needed to received data can be done here */
  protected buildModel(model: object): T {
    const builtModel = this.initializeFieldsToRemove({ ...model } as T);
    return builtModel;
  }

  protected initializeFieldsToRemove(model: T): T {
    return model;
  }

  /**Fields used locally in model T to be removed before writing into firestore */
  getFieldsToRemove(): string[] {
    return ['id'];
  }

  /**Any modification needed in T model before writing to firestore can be done here, e.g remove locally added variables */
  modifyModelDataBeforeWriting(model: T): T {
    this.getFieldsToRemove().forEach(field => {
      delete model[field];
    });

    return model;
  }

  protected setModelId(builtModel: T, id: string) {
    builtModel.id = id;
  }
  /**If you want to add aditional key: values to your implementation, override this method and set initial state with your added key: values*/
  setInitialState(): DataState {
    this.initialState = { [this.collectionName]: {}, filters: {} } as DataState;
    return this.initialState;
  }

  createDataReducer(): (state: DataState, action: FirestoreAction) => DataState {
    return (state: DataState = this.initialState, action: FirestoreAction): DataState => {
      const id = action['id'];

      switch (action.type) {
        /**When a document must be saved or updated */
        case this.ADDED_ACTION:
        case this.MODIFIED_ACTION:
        case this.UPSERTED_ACTION:
        case this.MODIFIED_LOCALLY_ACTION:
          let builtModel: T;
          //Locally modified data should already have the correct format
          if (action.type === this.MODIFIED_LOCALLY_ACTION) {
            builtModel = action.data as T;
          } else {
            builtModel = this.buildModel(action.data);
            /**Set basemodel id = document id from firestore */
            this.setModelId(builtModel, id);
          }

          if (action.type === this.MODIFIED_ACTION || action.type === this.MODIFIED_LOCALLY_ACTION) {
            const actualModel = state[this.collectionName][id];
            builtModel = { ...(actualModel as object), ...(builtModel as object) } as T;
          }
          /**Add received model to actual state with format documentId: model so it can be easily obtained from store by id */
          const newModel = { [builtModel.id]: builtModel };
          let actualStateModels = { ...state[this.collectionName], ...newModel };

          return {
            ...(state as object),
            [this.collectionName]: actualStateModels
          } as DataState;
        case this.DELETED_ACTION:
          actualStateModels = { ...state[this.collectionName] };
          delete actualStateModels[id];
          return {
            ...(state as object),
            [this.collectionName]: actualStateModels
          } as DataState;
        default:
          return state;
      }
    };
  }

  /**Done this way because this.featureSelector only gets its value after parent class (this one) is initialized, so the value initially is undefined */

  /**We Prevent creating new instances of the selector to keep memoization working */
  /**------------------------------------------------------------------------------------- */

  getAllEntitiesSelector(): MemoizedSelector<object, Entities<T>> {
    if (!this.allEntitiesSelector) {
      this.allEntitiesSelector = createSelector(this.featureSelector, state => {
        return state[this.collectionName] as Entities<T>;
      });
    }

    return this.allEntitiesSelector;
  }

  getEntityByIdSelector(id: string): MemoizedSelector<object, T> {
    return createSelector(this.getAllEntitiesSelector(), state => state[id]);
  }

  getConvertToArraySelector(): MemoizedSelector<object, T[]> {
    if (!this.convertToArraySelector) {
      this.convertToArraySelector = createSelector(this.getAllEntitiesSelector(), models => {
        return Object.keys(models).map(model => models[model] as T);
      });
    }

    return this.convertToArraySelector;
  }

  getCountSelector(): MemoizedSelector<object, number> {
    if (!this.countSelector) {
      this.countSelector = createSelector(this.getConvertToArraySelector(), array => {
        return array.length;
      });
    }

    return this.countSelector;
  }
  /**------------------------------------------------------------------------------------- */

  protected getOrCreateSelectorById<R>(
    selectorId: string,
    selectorToCreate: MemoizedSelector<object, R>
  ): MemoizedSelector<object, R> {
    if (!this.getSelectorById(selectorId)) {
      this.saveSelector(selectorId, selectorToCreate);
    }
    return this.getSelectorById(selectorId);
  }

  protected saveSelector(id: string, selector: MemoizedSelector<object, any>) {
    this.storedSelectors[id] = selector;
  }

  protected getSelectorById(id: string) {
    return this.storedSelectors[id];
  }

  abstract getCollectionReference(
    db: AngularFirestore,
    firestoreCollectionReference: FirestoreCollectionReference
  ): AngularFirestoreCollection;
}
