import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { dialCodes } from '@shared/utils/dial-codes';
import { DialCode } from '@core/types/dial-code.interface';

@Component({
  selector: 'app-dial-code-selector',
  templateUrl: './dial-code-selector.component.html',
  styleUrls: ['./dial-code-selector.component.scss']
})
export class DialCodeSelectorComponent implements OnInit {
  // constructor(private store: Store<AuthDataState>) {}

  countryNameCTRL = new FormControl();
  options: DialCode[] = dialCodes;
  countryChosen: DialCode = dialCodes.find(country => country.code === 'CO');
  filteredOptions: Observable<DialCode[]>;

  ngOnInit() {
    this.filteredOptions = this.countryNameCTRL.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(valueChosen: string | DialCode): DialCode[] {
    const name = typeof valueChosen === 'string' ? valueChosen : valueChosen.name;

    return this.options.filter(option => option.name.toLowerCase().includes(name.toLowerCase()));
  }

  displayFn(countryInfo?: DialCode): string | undefined {
    const name = countryInfo ? countryInfo.name : undefined;
    return name;
  }

  onCountrySelected({ option }: MatAutocompleteSelectedEvent): void {
    this.countryChosen = option.value;
  }
}
