import { Component, Input } from '@angular/core';
import { Dictionary } from '@core/types/dictionary.interface';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent {
  @Input()
  data: string;
  @Input()
  title: string;
  @Input()
  size: number;

  get QRSize(): Dictionary {
    return this.size ? { 'width.em': this.size, 'height.em': this.size } : {};
  }
}
