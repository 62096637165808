import { UserReducer } from '@store/users/user.firestore.reducer';
import { ShowReducer } from '@store/shows/show.firestore.reducer';
import {
  allFirestoreAddEffectTypes,
  allFirestoreModifyEffectTypes,
  allFirestoreDeleteEffectTypes,
  allFirestoreActionsToReducers,
  allFirestoreUpsertEffectTypes
} from './firestore.effects.types';
import { RegistrantReducer } from '@store/registrants/registrant.firestore.reducer';
import { CompanyReducer } from '@store/companies/company.firestore.reducer';
import { TicketReducer } from '@store/tickets/tickets.firestore.reducer';
import { PlanSubscriptionReducer } from '@store/plan-subscription/plan-subscription.firestore.reducer';
import { RegistrantEventMailListReducer } from '@store/registrants/registrant-event-mail-list.firestore-subcollection.reducer';
import { RegistrantEventMailStatusReducer } from '@store/registrants/registrant-event-mail-status.firestore-subcollection.reducer';
import { PlanReducer } from '@store/plans/plan.firestore.reducer';
import { PublicInfoReducer } from '@store/public-info/public-info.firestore.reducer';

/**This array must contain all firestore reducer instances to allow initializing
 * its redux action keys and  the redux Store with their own reducers */
export const allFirestoreReducers = [
  PlanReducer.getInstance(),
  ShowReducer.getInstance(),
  UserReducer.getInstance(),
  TicketReducer.getInstance(),
  CompanyReducer.getInstance(),
  RegistrantReducer.getInstance(),
  PlanSubscriptionReducer.getInstance(),
  RegistrantEventMailListReducer.getInstance(),
  RegistrantEventMailStatusReducer.getInstance(),
  PublicInfoReducer.getInstance()
];

/**Initialize all firestore effects types with firestore reducers own keys and its initial state */
export function initializeFirestoreEffects() {
  allFirestoreReducers.forEach(reducer => {
    const addAction = reducer.ADD_ACTION;
    const upsertAction = reducer.UPSERT_ACTION;
    const deleteAction = reducer.DELETE_ACTION;
    const modifyAction = reducer.MODIFY_ACTION;

    if (reducer.AUTO_SET_INNER_COLLECTION_ID) {
      const collectionId = reducer.INNER_COLLECTION_ID;
      if (!collectionId) {
        throw new Error(
          `if AUTO_SET_INNER_COLLECTION_ID is true, you must define INNER_COLLECTION_ID in ${
            reducer.collectionName
          } collection reducer`
        );
      }
    }

    /**This couldn't be done in parent constructor because Typescript inheritance works from parent to child,
     thus preventing the child class to have the keys successfully  initialized*/

    /**Add all actions to be successfully filtered by firebase effects */
    allFirestoreAddEffectTypes.push(addAction);
    allFirestoreUpsertEffectTypes.push(upsertAction);
    allFirestoreDeleteEffectTypes.push(deleteAction);
    allFirestoreModifyEffectTypes.push(modifyAction);

    /**Add reducer for each action so all reducer data can be extracted in firebase effects */
    allFirestoreActionsToReducers[addAction] = reducer;
    allFirestoreActionsToReducers[upsertAction] = reducer;
    allFirestoreActionsToReducers[deleteAction] = reducer;
    allFirestoreActionsToReducers[modifyAction] = reducer;

    reducer.setInitialState();
  });
}
