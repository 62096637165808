import { Action, Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap, catchError, withLatestFrom, tap, map, delay } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { AuthService } from '@services/auth/auth.service';
import { AuthActionsType } from './auth.actions.name';
import {
  AuthErrorAction,
  UserLoadedAction,
  LoginWithCredentialsAction,
  RegisterAction,
  RecoverPasswordAction,
  PasswordRecoveredAction,
  SetUrlForRedirectAction,
  ChangePasswordAction,
  PasswordChangedAction,
  LogoutAction
} from './auth.actions';
import { FirestoreReduxService } from '../../services/firebase/firestore-redux.service';
import {
  createEqualsQueryAction,
  ModifyDataAction,
  createSortAction,
  SortActionOrder
} from '@store/firestore/firestore.actions';
import { User } from '@models/user';
import { AuthDataState } from '@store/auth/auth.reducer';
import { authDataState } from '@store/auth';
import { Router } from '@angular/router';
import { UserReducer } from '@store/users/user.firestore.reducer';
import { SnackBarService } from '@services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthEffects {
  private reduxListenersStarted = false;

  constructor(
    private router: Router,
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AuthDataState>,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private firestoreReduxService: FirestoreReduxService
  ) {}

  /**Used to load logged in user if connected */
  @Effect()
  loadUserSelf$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionsType.LOAD_USER_SELF),
    switchMap(() => {
      return this.authService.loadUser().pipe(
        withLatestFrom(this.store.pipe(select(authDataState))),
        map(([user, { redirectUrl }]) => {
          /**If logged then start listening for changes in desired collections filtered by userId, companyId or anything you need**/
          if (user) {
            if (!user.isActive) {
              this.translate
                .get('snackbar.inactiveUser')
                .subscribe(message => this.snackBarService.open({ msg: message, type: 'error' }));
              return new LogoutAction();
            }

            /**Prevents starting multiple instances of firestore redux if unneeded */
            if (!this.reduxListenersStarted) {
              this.startListeningFirestoreRedux(user);
            }

            if (redirectUrl) {
              const parsedUrl = this.router.parseUrl(redirectUrl);

              const splitUrl = redirectUrl.split('?');
              if (splitUrl.length > 0) {
                redirectUrl = redirectUrl.replace('?' + splitUrl[1], '');
              }

              this.router.navigate([redirectUrl], { queryParams: parsedUrl.queryParams });
              // Store the attempted URL for redirecting
              this.store.dispatch(new SetUrlForRedirectAction(undefined));
            } else {
              this.router.navigate(['/dashboard']);
            }
          }
          return new UserLoadedAction(user);
        })
      );
    })
  );

  @Effect()
  registerWithEmailAndPassword$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionsType.REGISTER),
    switchMap(({ email, password, fullName }: RegisterAction) => {
      //Done this way because if catching error in the outer this.actions$.pipe() would break the chain after handling that error
      //https://stackoverflow.com/questions/45523127/observable-with-rx-broken-after-error/45523762#45523762
      return this.authService.register(email, password, fullName).pipe(
        map(user => new UserLoadedAction(user)),
        catchError(error => of(new AuthErrorAction(error.message)))
      );
    })
  );

  @Effect()
  forgotPassword$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionsType.RECOVER_PASSWORD),
    switchMap(({ email }: RecoverPasswordAction) => {
      //Done this way because if catching error in the outer this.actions$.pipe() would break the chain after handling that error
      //https://stackoverflow.com/questions/45523127/observable-with-rx-broken-after-error/45523762#45523762
      return this.authService.forgotPassword(email).pipe(
        map(() => new PasswordRecoveredAction()),
        catchError(error => of(new AuthErrorAction(error.message)))
      );
    })
  );

  @Effect()
  loginWithCredentials$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionsType.LOGIN_WITH_CREDENTIALS),
    switchMap(({ email, password }: LoginWithCredentialsAction) => {
      //Done this way because if catching error in the outer this.actions$.pipe() would break the chain after handling that error
      //https://stackoverflow.com/questions/45523127/observable-with-rx-broken-after-error/45523762#45523762
      return this.authService.loginWithEmailAndPassword(email, password).pipe(
        map(user => new UserLoadedAction(user)),
        catchError(error => of(new AuthErrorAction(error.message)))
      );
    })
  );

  @Effect()
  loginWithGoogle$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionsType.LOGIN_WITH_GOOGLE),
    switchMap(() => {
      //Done this way because if catching error in the outer this.actions$.pipe() would break the chain after handling that error
      //https://stackoverflow.com/questions/45523127/observable-with-rx-broken-after-error/45523762#45523762
      return this.authService.loginWithGoogle().pipe(
        map(user => new UserLoadedAction(user)),
        catchError(error => of(new AuthErrorAction(error.message)))
      );
    })
  );

  @Effect()
  changePassword$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionsType.CHANGE_PASSWORD),
    withLatestFrom(this.store.pipe(select(authDataState))),
    switchMap(([action, { userSelf }]) => {
      const innerAction = action as ChangePasswordAction;
      const { password } = innerAction;

      //Done this way because if catching error in the outer this.actions$.pipe() would break the chain after handling that error
      //https://stackoverflow.com/questions/45523127/observable-with-rx-broken-after-error/45523762#45523762
      return this.authService.changePassword(password).pipe(
        map(_ => this.store.dispatch(new PasswordChangedAction())),
        tap(_ => {
          const modifyUserAction = UserReducer.getInstance().MODIFY_ACTION;
          const userObject = { ...userSelf, changePassword: false };
          this.store.dispatch(new ModifyDataAction(modifyUserAction, userObject));
        }),
        delay(1000),
        map(_ => new LogoutAction()),
        catchError(error => of(new AuthErrorAction(error.message)))
      );
    })
  );

  @Effect({ dispatch: false })
  logout: Observable<Action> = this.actions$.pipe(
    ofType(AuthActionsType.LOGOUT),
    tap(() => {
      this.authService.logout().then(() => {
        this.reduxListenersStarted = false;
        this.firestoreReduxService.unsubscribeAll();
        this.router.navigate(['/']);
      });
    })
  );

  private startListeningFirestoreRedux(user: User) {
    this.reduxListenersStarted = true;
    const companyId = user.companyId;
    const myUserId = user.userId;
    const companyIdQuery = createEqualsQueryAction('companyId', companyId);
    const mySuperIdQuery = createEqualsQueryAction('superUserId', myUserId);
    const sortByStartDateDesc = createSortAction('startDate', SortActionOrder.DESC);

    this.firestoreReduxService.startPlanFirestoreReduxListener([]);
    this.firestoreReduxService.startUserFirestoreReduxListener([mySuperIdQuery], companyId);
    this.firestoreReduxService.startShowFirestoreReduxListener([companyIdQuery, sortByStartDateDesc], companyId);
    this.firestoreReduxService.startCompanyFirestoreReduxListener([companyIdQuery], companyId);
    this.firestoreReduxService.startSubscriptionFirestoreReduxListener([companyIdQuery], companyId);
  }
}
