import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { Ticket } from '@core/models/ticket';
import { ticketDataState } from '.';
import { FIRESTORE_COLLECTION_TICKETS } from '@store/firestore/allFirestoreCollections';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { FirestoreCollectionReducer } from '@store/firestore/firestore-collection.reducer';

export interface TicketDataState extends FirestoreReducerDataState<Ticket> {}

export class TicketReducer extends FirestoreCollectionReducer<Ticket, TicketDataState> {
  private static instance: TicketReducer;

  featureSelector = ticketDataState;
  collectionName = FIRESTORE_COLLECTION_TICKETS;

  INNER_COLLECTION_ID = 'ticketId';

  ADD_ACTION = '[Ticket] Add ticket';
  UPSERT_ACTION = '[Ticket] Upsert ticket';
  DELETE_ACTION = '[Ticket] Delete ticket';
  MODIFY_ACTION = '[Ticket] Modify ticket';

  ADDED_ACTION = '[Ticket] Added ticket from firebase';
  DELETED_ACTION = '[Ticket] Deleted ticket from firebase';
  MODIFIED_ACTION = '[Ticket] Modified ticket from firebase';
  UPSERTED_ACTION = '[Ticket] Upserted ticket from firebase';
  MODIFIED_LOCALLY_ACTION = '[Ticket] Modify locally ticket';

  static getInstance() {
    if (!TicketReducer.instance) {
      TicketReducer.instance = new TicketReducer();
    }
    return TicketReducer.instance;
  }

  /**Selector in charge of extracting an array with all tickets per show  */
  getTicketsByShowIdSelector(showId: string): MemoizedSelector<object, Ticket[]> {
    const CUSTOM_ID = `SHOW_${showId}_TICKETS`;
    const selectorToCreate = createSelector(this.getConvertToArraySelector(), allTickets => {
      return allTickets.filter(ticket => {
        return ticket.eventId === showId;
      });
    });

    return this.getOrCreateSelectorById(CUSTOM_ID, selectorToCreate);
  }
}
