import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action, Store, select } from '@ngrx/store';
import { EmailActionsType } from '@store/email/email.actions.name';
import { switchMap, map } from 'rxjs/operators';
import { EmailShowLoaded, EmailLoadShow, EmailEmailSaved } from '@store/email/email.actions';
import { ShowReducer } from '@store/shows/show.firestore.reducer';
import { AppState } from '@store';
import { ModifyDataAction } from '@store/firestore/firestore.actions';

@Injectable()
export class EmailEffects {
  showReducer: ShowReducer;

  constructor(private actions$: Actions, private store: Store<AppState>) {
    this.showReducer = ShowReducer.getInstance();
  }

  @Effect()
  loadShow$: Observable<Action> = this.actions$.pipe(
    ofType(EmailActionsType.LOAD_SHOW),
    switchMap((action: EmailLoadShow) => {
      const showId = action.showId;
      const selector = this.showReducer.getEntityByIdSelector(showId);
      return this.store.pipe(
        select(selector),
        map(show => new EmailShowLoaded(show))
      );
    })
  );

  @Effect()
  saveShow$: Observable<Action> = this.actions$.pipe(
    ofType(EmailActionsType.SAVE_EMAIL),
    switchMap((action: EmailShowLoaded) => {
      const show = action.show;
      const modifyAction = this.showReducer.MODIFY_ACTION;
      return [new ModifyDataAction(modifyAction, show), new EmailEmailSaved(show)];
    })
  );
}
