import { Component, Inject } from '@angular/core';
import { FormValidators } from '@shared/forms/form-validators';
import { FormGroup } from '@angular/forms';
import { FormControlStructure } from '@core/models/form-control-structure';
import { WodenFormBuilder } from '@shared/forms/woden-form-builder';
import { Dictionary } from '@core/types/dictionary.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-one-text-field-form',
  templateUrl: './one-text-field-form.component.html',
  styleUrls: ['./one-text-field-form.component.css']
})
export class OneTextFieldFormComponent extends FormValidators {
  myForm: FormGroup;
  labelText = '';
  buttonText = '';

  private DATA_FORM_CTRL_KEY = 'dataFormCtrl';

  constructor(
    public dialogRef: MatDialogRef<OneTextFieldFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dictionary
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.labelText = this.data.labelText as string;
    this.buttonText = this.data.buttonText as string;
  }

  initForm(): void {
    const dataFormCtrl = new FormControlStructure(this.DATA_FORM_CTRL_KEY, 'forRequired');
    const formBuilder = new WodenFormBuilder([dataFormCtrl]);
    this.myForm = formBuilder.getFormInstance();
  }

  onFinish(): void {
    const { valid, value } = this.validateForm();

    if (valid) {
      const { dataFormCtrl } = value;
      this.dialogRef.close({ data: dataFormCtrl });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
