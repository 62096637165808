import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material.module';
// Components
import { FileListUploadComponent } from './components/file-list-upload/file-list-upload.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { GoBackComponent } from './components/go-back/go-back.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { OneTextFieldFormComponent } from './components/one-text-field-form/one-text-field-form.component';
import { SaveChangesComponent } from './components/save-changes/save-changes.component';
import { FormIptComponent } from './components/form-ipt/form-ipt.component';
import { IptErrorsComponent } from './components/ipt-errors/ipt-errors.component';
import { ToggleTypeIptComponent } from './components/toggle-type-ipt/toggle-type-ipt.component';
import { DialCodeSelectorComponent } from './components/dial-code-selector/dial-code-selector.component';
// Pipes
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency-pipe/custom-currency.pipe';
// import { CurrencyPipe } from '@angular/common';
// Directive
import { NumberFormatterDirective } from './number-formatter.directive';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({}),
    QRCodeModule,
    RouterModule
  ],
  declarations: [
    ConfirmationDialogComponent,
    DialCodeSelectorComponent,
    FileListUploadComponent,
    FormIptComponent,
    GoBackComponent,
    IptErrorsComponent,
    LoadingComponent,
    QrCodeComponent,
    OneTextFieldFormComponent,
    SnackBarComponent,
    SaveChangesComponent,
    ToggleTypeIptComponent,
    NumberFormatPipe,
    CustomCurrencyPipe,
    NumberFormatterDirective
  ],
  exports: [
    ConfirmationDialogComponent,
    DialCodeSelectorComponent,
    FileListUploadComponent,
    FormIptComponent,
    GoBackComponent,
    LoadingComponent,
    MaterialModule,
    QrCodeComponent,
    SaveChangesComponent,
    SnackBarComponent,
    TranslateModule,
    NumberFormatPipe,
    NumberFormatterDirective
  ],
  entryComponents: [ConfirmationDialogComponent, SnackBarComponent, OneTextFieldFormComponent],
  providers: [CustomCurrencyPipe]
})
export class SharedModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
