import { Action } from '@ngrx/store';
import { DashboardActionsType } from './dashboard.actions.name';
import { Show } from '../../core/models/show';

export type DashboardAction =
  | DashboardShowSelected
  | DashboardAllShowDetails
  | DashboardShowsSearch
  | DashboardShowsFilter
  | DashboardShowsChanged
  | DashboardRevenuesChanged
  | DashboardRegistrantsChanged
  | DashboardAttendeesChanged
  | DashboardShowsPageChanged;

export const FILTER_NONE = 0;
export const FILTER_UPCOMING = 1;
export const FILTER_PAST = 2;

export class DashboardShowSelected implements Action {
  readonly type = DashboardActionsType.SHOW_SELECTED;
  constructor(public showId: string) {}
}

export class DashboardAllShowDetails implements Action {
  readonly type = DashboardActionsType.ALL_SHOWS_DETAIL;
}

export class DashboardShowsSearch implements Action {
  readonly type = DashboardActionsType.SHOWS_SEARCH;
  constructor(public search: string) {}
}

export class DashboardShowsFilter implements Action {
  readonly type = DashboardActionsType.SHOWS_FILTER;
  constructor(public filter: number) {}
}

export class DashboardShowsChanged implements Action {
  readonly type = DashboardActionsType.SHOWS_UPDATED;
  constructor(public shows: Show[]) {}
}

export class DashboardRevenuesChanged implements Action {
  readonly type = DashboardActionsType.REVENUES_UPDATED;
  constructor(public revenues: number) {}
}

export class DashboardRegistrantsChanged implements Action {
  readonly type = DashboardActionsType.REGISTRANTS_UPDATED;
  constructor(public registrants: number) {}
}

export class DashboardAttendeesChanged implements Action {
  readonly type = DashboardActionsType.ATTENDEES_UPDATED;
  constructor(public attendees: number) {}
}

export class DashboardShowsPageChanged implements Action {
  readonly type = DashboardActionsType.SHOWS_PAGE_CHANGED;
  constructor(public pageIndex: number) {}
}
