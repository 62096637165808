import { Action } from '@ngrx/store';
import { ShowDetailActionsType } from '@store/show-detail/show-detail.actions.name';
import { Show } from '@core/models/show';
import { Registrant } from '@core/models/registrant';

export type ShowDetailAction =
  | ShowDetailLoadShow
  | ShowDetailClearData
  | ShowDetailSearchRegistrants
  | ShowDetailLoadRegistrants
  | ShowDetailFilterRegistrant
  | ShowDetailLoadShowAggregateData
  | ShowDetailShowLoaded
  | ShowDetailRegistrantsLoaded
  | ShowDetailRevenuesChanged
  | ShowDetailRegistrantsChanged
  | ShowDetailAttendeesChanged
  | ShowDetailSelectAllRegistrantsInTab;

export const FILTER_ALL = 150;
export const FILTER_INVITED = 0;
export const FILTER_NOT_GOING = 1;
export const FILTER_TICKET_ASSIGNED = 2;
export const FILTER_ATTENDEES = 3;

export class ShowDetailLoadShow implements Action {
  readonly type = ShowDetailActionsType.LOAD_SHOW;
  constructor(public showId: string) {}
}

export class ShowDetailClearData implements Action {
  readonly type = ShowDetailActionsType.CLEAR_DATA;
}

export class ShowDetailSearchRegistrants implements Action {
  readonly type = ShowDetailActionsType.SEARCH_REGISTRANTS;
  constructor(public showId: string, public text: string) {}
}

export class ShowDetailFilterRegistrant implements Action {
  readonly type = ShowDetailActionsType.FILTER_REGISTRANTS;
  constructor(public showId: string, public filter: number) {}
}

export class ShowDetailLoadRegistrants implements Action {
  readonly type = ShowDetailActionsType.LOAD_REGISTRANTS;
  constructor(public showId: string) {}
}

export class ShowDetailLoadShowAggregateData implements Action {
  readonly type = ShowDetailActionsType.LOAD_SHOW_AGGREGATED_DATA;
  constructor(public showId: string) {}
}

export class ShowDetailShowLoaded implements Action {
  readonly type = ShowDetailActionsType.SHOW_LOADED;
  constructor(public show: Show) {}
}

export class ShowDetailRegistrantsLoaded implements Action {
  readonly type = ShowDetailActionsType.REGISTRANTS_LOADED;
  constructor(public registrants: Registrant[]) {}
}

export class ShowDetailRevenuesChanged implements Action {
  readonly type = ShowDetailActionsType.REVENUES_UPDATED;
  constructor(public revenues: number) {}
}

export class ShowDetailRegistrantsChanged implements Action {
  readonly type = ShowDetailActionsType.REGISTRANTS_UPDATED;
  constructor(public registrants: number) {}
}

export class ShowDetailAttendeesChanged implements Action {
  readonly type = ShowDetailActionsType.ATTENDEES_UPDATED;
  constructor(public attendees: number) {}
}

export class ShowDetailSelectAllRegistrantsInTab implements Action {
  readonly type = ShowDetailActionsType.SELECT_ALL_REGISTRANTS_IN_TAB;
  constructor() {}
}
