import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

export class SubscriptionComponent implements OnInit, OnDestroy {
  protected stopSubscription = new Subject();

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.stopSubscription.next();
    this.stopSubscription.unsubscribe();
  }
}
