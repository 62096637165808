import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-save-changes',
  templateUrl: './save-changes.component.html',
  styleUrls: ['./save-changes.component.scss']
})
export class SaveChangesComponent {
  @Output()
  response = new EventEmitter<boolean>();

  @Input()
  cancelTxt: string;
  @Input()
  acceptTxt: string;
  @Input()
  loading: string;
  @Input()
  message: string;

  onCancel(): void {
    this.response.emit(false);
  }

  onAccept(): void {
    this.response.emit(true);
  }
}
