import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { Dictionary } from '@core/types/dictionary.interface';
import { SidenavDataState } from '@store/sidenav/sidenav.reducer';
import { sidenavDataState } from '@store/sidenav';
import { CustomValidationErrors } from '@core/types/custom-validation-errors.interface';

@Component({
  selector: 'app-ipt-errors',
  templateUrl: './ipt-errors.component.html',
  styleUrls: ['./ipt-errors.component.scss']
})
export class IptErrorsComponent implements OnInit {
  @Input()
  set errors({ name, errors }: CustomValidationErrors) {
    const _errors = [];
    Object.keys(errors).forEach(errorkey => {
      const value = errors[errorkey];
      const label = `${name}.${errorkey}`;
      const param = {};

      if (typeof value !== 'boolean') {
        const isObject = typeof value === 'object';
        if (isObject) {
          Object.keys(value).forEach(valueKey => (param[valueKey] = value[valueKey]));
        } else {
          param[value] = value;
        }
      }
      return _errors.push({ label, param });
    });
    this._errors = _errors;
  }

  @Input()
  keyTranslation = 'errors.';
  @Input()
  theme = '';

  _errors: Dictionary[];

  lang$: Observable<string>;

  constructor(private readonly translate: TranslateService, private readonly store: Store<SidenavDataState>) {
    this.translate.setDefaultLang('en');

    this.store.pipe(select(sidenavDataState)).subscribe(({ currentLang }) => {
      this.lang$ = of(currentLang);
    });
  }

  ngOnInit() {
    this.lang$.subscribe(lang => this.translate.use(lang));
  }

  get classError(): Dictionary {
    const classes = { lite: this.theme === 'lite' };
    return classes;
  }
}
