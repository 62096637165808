import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { DashboardDataState } from '@store/dashboard/dashboard.reducer';
import { takeUntil } from 'rxjs/operators';

import { Dictionary } from '@core/types/dictionary.interface';
import { SnackBarService } from '@services/snack-bar.service';
import { CMSPluginService } from '@services/cms-plugin.service';
import { SubscriptionComponent } from '@shared/components/subscription.component';
import { myCompanySelector } from '@store/companies/company.firestore.reducer';
import { Company } from '@core/models/company';
import { User } from '@core/models/user';

@Component({
  selector: 'app-cms-list-connector',
  templateUrl: './cms-list-connector.component.html',
  styleUrls: ['./cms-list-connector.component.scss']
})
export class CMSListConnectorComponent extends SubscriptionComponent {
  cmsList: any[];
  cmsChosen: any;
  generatingKey: boolean;
  accessToken: string;
  apiKey = 'aPikEyFoRTokEn';
  myCompany: Company;
  userSelf: User;
  permissionToGenerate: boolean;
  thereIsApiKey: boolean;

  constructor(
    private store: Store<DashboardDataState>,
    public dialogRef: MatDialogRef<CMSListConnectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cmsService: CMSPluginService,
    private snackbar: SnackBarService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.cmsList = [
      { active: true, name: 'woocommerce' },
      { active: false, name: 'shopify' },
      { active: false, name: 'magento' },
      { active: false, name: 'prestashop' }
    ];
    const { userSelf } = this.data;
    this.userSelf = userSelf as User;
    this.permissionToGenerate = userSelf.isSuperUser;

    this.store
      .pipe(
        takeUntil(this.stopSubscription),
        select(myCompanySelector)
      )
      .subscribe((company: Company) => {
        if (company && JSON.stringify(this.myCompany) !== JSON.stringify(company)) {
          this.myCompany = company;
          const thereIsApiKey = company.apiKey ? company.apiKey.key.length > 0 : false;
          this.thereIsApiKey = thereIsApiKey;
          this.accessToken = thereIsApiKey ? company.apiKey.key : '';
        }
      });
  }

  cntClass(cmsActive: boolean): Dictionary {
    return { inactive: !cmsActive, active: cmsActive };
  }

  selectCMS(cms: any): void {
    this.cmsChosen = cms;
  }

  goBack(): void {
    this.cmsChosen = undefined;
  }

  tryGenerateApiKey(): void {
    if (this.permissionToGenerate) {
      this.cmsService.generateApiKey(this.userSelf.id).subscribe(
        () => {},
        ({ error }: HttpErrorResponse) => {
          console.log('generateApiKey >> err', error);
        }
      );
    }
  }

  copyToken(): void {
    const textArea = document.createElement('textarea');
    textArea.id = 'txtArea';
    textArea.style.height = '0px';
    document.body.appendChild(textArea);
    textArea.value = this.accessToken;

    const selector = document.querySelector('#txtArea') as HTMLInputElement;
    selector.select();
    document.execCommand('copy');

    document.body.removeChild(textArea);
    this.translate.get('cmsList.tokenCopied').subscribe(msg => {
      this.snackbar.open({ msg, type: 'info' }, 8000);
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
