import { Directive, Renderer2, ElementRef, HostListener, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomCurrencyPipe } from './pipes/custom-currency-pipe/custom-currency.pipe';

@Directive({
  selector: '[appNumberFormatter]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFormatterDirective),
      multi: true
    }
  ]
})
export class NumberFormatterDirective implements ControlValueAccessor {
  onChange: (arg: string) => void;
  onTouched: () => void;
  element: any;

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private currencyPipe: CustomCurrencyPipe) {
    this.element = this.elementRef.nativeElement;
  }

  writeValue(value: any): void {
    const element = this.element;
    const newValue = this.currencyPipe.transform(value);
    this.renderer.setProperty(element, 'value', newValue);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.element, 'disabled', isDisabled);
  }

  // @HostListener('focus', ['$event.target.value'])
  // onFocus() {
  //   this.onTouched();
  // }

  @HostListener('input', ['$event.target.value'])
  input(value: any) {
    const valueParsed = this.currencyPipe.parse(value);
    const newValue = this.currencyPipe.transform(valueParsed);
    this.onChange(valueParsed);
    this.renderer.setProperty(this.element, 'value', newValue);
  }
}
