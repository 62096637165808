import { Show } from '@core/models/show';
import { ShowDetailActionsType } from '@store/show-detail/show-detail.actions.name';
import { ShowDetailAction } from '@store/show-detail/show-detail.actions';
import { Registrant } from '@core/models/registrant';

export interface ShowDetailDataState {
  show: Show;
  search: string;
  loading: boolean;
  revenuesNumber: number;
  attendeesNumber: number;
  selectedTabIndex: number;
  registrantsNumber: number;
  registrants: Registrant[];
}

export const initialState: ShowDetailDataState = {
  show: null,
  search: '',
  loading: false,
  revenuesNumber: 0,
  attendeesNumber: 0,
  selectedTabIndex: 0,
  registrantsNumber: 0,
  registrants: []
};

export function showDetailDataReducer(state = initialState, action: ShowDetailAction): ShowDetailDataState {
  switch (action.type) {
    case ShowDetailActionsType.LOAD_SHOW:
    case ShowDetailActionsType.LOAD_REGISTRANTS:
      return { ...state, loading: true };
    case ShowDetailActionsType.CLEAR_DATA:
      return initialState;
    case ShowDetailActionsType.SHOW_LOADED:
      const show = action.show;
      return { ...state, loading: false, show };
    case ShowDetailActionsType.REGISTRANTS_LOADED:
      const registrants = action.registrants;
      return { ...state, loading: false, registrants };
    case ShowDetailActionsType.ATTENDEES_UPDATED:
      const attendeesNumber = action.attendees;
      return { ...state, attendeesNumber };
    case ShowDetailActionsType.REGISTRANTS_UPDATED:
      const registrantsNumber = action.registrants;
      return { ...state, registrantsNumber };
    case ShowDetailActionsType.REVENUES_UPDATED:
      const revenuesNumber = action.revenues;
      return { ...state, revenuesNumber };
    case ShowDetailActionsType.SEARCH_REGISTRANTS:
      const search = action.text;
      return { ...state, search };
    case ShowDetailActionsType.FILTER_REGISTRANTS:
      const selectedTabIndex = action.filter;
      return { ...state, selectedTabIndex };
    default:
      return state;
  }
}
