import { Action } from '@ngrx/store';
import { BaseModel } from '@core/models/basemodel';

export enum QueryActionOperators {
  GREATER_THAN = '>',
  LESS_THAN = '<',
  EQUALS = '==',
  GREATER_THAN_OR_EQUALS = '>=',
  LESS_THAN_OR_EQUALS = '<='
}

export enum FilterActionType {
  SORT = 'sort',
  WHERE = 'where'
}

export enum SortActionOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export interface FilterAction {
  action: FilterActionType;
  field: string;
}

export class QueryAction implements FilterAction {
  field: string;
  action = FilterActionType.WHERE;
  comparator: QueryActionOperators;
  value: any;
}

export class SortAction implements FilterAction {
  field: string;
  order: SortActionOrder;
  action = FilterActionType.SORT;
}

function createQueryAction(field: string, operator: QueryActionOperators, value: any): QueryAction {
  return {
    field,
    value,
    comparator: operator,
    action: FilterActionType.WHERE
  };
}

export function createEqualsQueryAction(modelField: string, value: any): QueryAction {
  return createQueryAction(modelField, QueryActionOperators.EQUALS, value);
}

export function createGreaterThanQueryAction(modelField: string, value: any): QueryAction {
  return createQueryAction(modelField, QueryActionOperators.GREATER_THAN, value);
}

export function createLessThanQueryAction(modelField: string, value: any): QueryAction {
  return createQueryAction(modelField, QueryActionOperators.LESS_THAN, value);
}

export function createLessThanOrEqualsQueryAction(modelField: string, value: any): QueryAction {
  return createQueryAction(modelField, QueryActionOperators.LESS_THAN_OR_EQUALS, value);
}

export function createGreaterThanOrEqualsAction(modelField: string, value: any): QueryAction {
  return createQueryAction(modelField, QueryActionOperators.GREATER_THAN_OR_EQUALS, value);
}

export function createSortAction(field: string, order: SortActionOrder) {
  return {
    order,
    field,
    action: FilterActionType.SORT
  };
}

/**All Firestore related actions being them launched from Firestore redux or from our own code.*/

export type FirestoreAction =
  | UpsertDataAction
  | AddDataAction
  | ModifyDataAction
  | DeleteDataAction
  | AddedDataAction
  | DeletedDataAction
  | UpsertedDataAction
  | ModifiedDataAction;

/** As all Firestore reducers will use the same actions but the type must be different,
 *we request the @param type key as a parameter*/

export class UpsertDataAction implements Action {
  constructor(public readonly type: string, public data: BaseModel) {}
}

export class AddDataAction implements Action {
  constructor(public readonly type: string, public data: BaseModel) {}
}

export class DeleteDataAction implements Action {
  constructor(public readonly type: string, public id: string, public data?: BaseModel) {}
}

export class ModifyDataAction implements Action {
  constructor(public readonly type: string, public data: BaseModel) {}
}

export class UpsertedDataAction implements Action {
  constructor(public readonly type: string, public id: string, public data: BaseModel, public ancestorsId = '') {}
}

export class AddedDataAction implements Action {
  constructor(public readonly type: string, public id: string, public data: BaseModel, public ancestorsId = '') {}
}

export class DeletedDataAction implements Action {
  constructor(public readonly type: string, public id: string, public data: BaseModel, public ancestorsId = '') {}
}

export class ModifiedDataAction implements Action {
  constructor(public readonly type: string, public id: string, public data: BaseModel, public ancestorsId = '') {}
}
