import { Injectable, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';

import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
/**
 * Service used to manage the snack-bar cmp. Used to send feedback to the user when he has previously made some action
 */
export class SnackBarService implements OnInit {
  constructor(public snackBar: MatSnackBar, private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.use('en');
  }

  open(data: any, duration = 5000, verticalPosition = 'top' as 'top'): MatSnackBarRef<any> {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      data,
      duration,
      verticalPosition
    });
  }

  demoPlanEventFull(maxInvites): void {
    this.translate.get(['snackbar.demoPlanEventFull', 'snackbar.upgrade'], { value: maxInvites }).subscribe(message => {
      const data = {
        msg: message['snackbar.demoPlanEventFull'],
        txtBtn: message['snackbar.upgrade'],
        btnLink: 'payments',
        type: 'warning'
      };

      return this.snackBar.openFromComponent(SnackBarComponent, {
        data,
        duration: 1800000
      });
    });
  }

  subscriptionOver(): void {
    this.translate.get(['snackbar.subscriptionOver', 'snackbar.subscribe']).subscribe(message => {
      const data = {
        msg: message['snackbar.subscriptionOver'],
        txtBtn: message['snackbar.subscribe'],
        btnLink: 'payments',
        type: 'error'
      };

      return this.snackBar.openFromComponent(SnackBarComponent, {
        data,
        duration: 5000
      });
    });
  }
}
