import { Subject } from 'rxjs';
import { distinctUntilChanged, delay } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

import { authDataState } from '@store/auth';
import { AuthDataState } from '@store/auth/auth.reducer';
import { MatSidenav, MatDialog } from '@angular/material';
import { LogoutAction } from '@store/auth/auth.actions';
import { myCompanySelector } from '@store/companies/company.firestore.reducer';
import { Company } from '@core/models/company';
import { stepperDataState } from '@store/stepper';
import { sidenavDataState } from '@store/sidenav';
import { ShowSidenav, ToggleCMSList, ToggleToolbar, SetMobileResoltion } from '@store/sidenav/sidenav.actions';
import { User } from '@core/models/user';
// import { CMSPluginService } from '@services/cms-plugin.service';
import { CMSListConnectorComponent } from '../cms-list-connector/cms-list-connector.component';

const DELAY_TO_UPDATE_UI = 300;
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  userEmail = '';
  companyName = '';
  myCompany: Company;
  sidenav: MatSidenav;
  mobileQuery: MediaQueryList;
  opened: boolean;
  showToolbar = false;
  isAuthenticated = false;
  mediaChange = new Subject<boolean>();
  companyImg = 'https://eloboosts.com/boostpanel/assets/img/avatars/0.png';
  stepperActive: boolean;
  cmsDialogOpened: boolean;
  userSelf: User;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private store: Store<AuthDataState>,
    public dialog: MatDialog
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQuery.addListener(() => changeDetectorRef.detectChanges());
  }

  ngOnInit() {
    this.store
      .pipe(
        select(authDataState),
        delay(DELAY_TO_UPDATE_UI)
      )
      .subscribe(({ userSelf, isAuthenticated }) => {
        this.isAuthenticated = isAuthenticated;
        if (userSelf) {
          this.userSelf = userSelf;
          this.userEmail = userSelf.email;
        }
      });

    this.store.pipe(select(stepperDataState)).subscribe(({ stepperActive }) => {
      this.store.dispatch(new ShowSidenav(!stepperActive));
      if (stepperActive) {
        this.store.dispatch(new ToggleToolbar(!stepperActive));
      }
    });

    this.store
      .pipe(
        select(sidenavDataState),
        delay(DELAY_TO_UPDATE_UI)
      )
      .subscribe(({ opened, showCMSList, showToolbar }) => {
        this.opened = opened;
        this.showToolbar = showToolbar;

        if (showCMSList && !this.cmsDialogOpened) {
          this.cmsDialogOpened = true;
          const dialogRef = this.dialog.open(CMSListConnectorComponent, {
            width: '700px',
            data: {
              userSelf: this.userSelf
            }
          });

          dialogRef
            .afterClosed()
            .pipe()
            .subscribe(() => {
              this.store.dispatch(new ToggleCMSList('woocommerce'));
              this.cmsDialogOpened = false;
            });
        }
      });

    this.store
      .pipe(
        select(myCompanySelector),
        delay(DELAY_TO_UPDATE_UI)
      )
      .subscribe(companies => {
        if (!companies) {
          return;
        }
        this.myCompany = companies;
        this.companyName = this.myCompany.name;

        const imageProfile = this.myCompany.preferences.imageProfile;
        if (imageProfile && imageProfile !== this.companyImg) {
          setTimeout(() => {
            this.companyImg = this.myCompany.preferences.imageProfile;
          }, 500);
        }
      });

    this.mediaChange.pipe(distinctUntilChanged()).subscribe(isMobile => {
      if (!isMobile) {
        this.store.dispatch(new ShowSidenav(true));
      } else {
        this.store.dispatch(new ShowSidenav(false));
      }
      this.store.dispatch(new SetMobileResoltion(isMobile));
    });
  }

  get isMobile(): any {
    this.mediaChange.next(this.mobileQuery.matches);
    return { 'is-mobile': this.mobileQuery.matches, dark: !this.showToolbar };
  }

  tryCloseSideNav(): void {
    if (this.mobileQuery.matches) {
      this.store.dispatch(new ShowSidenav(false));
    }
  }

  toggleSideNav(): void {
    this.store.dispatch(new ShowSidenav(!this.opened));
  }

  showCMSList(cms: string): void {
    this.store.dispatch(new ToggleCMSList(cms));
  }

  onLogout() {
    this.store.dispatch(new LogoutAction());
  }
}
