import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  private MAX_NUMBER = 999999;
  private MILLION_LETTER = 'M';
  private THOUSAND_SEPARATOR = ',';

  transform(value: number, onlyCommas = false): any {
    if (!value) {
      return 0;
    }

    if (onlyCommas || value < this.MAX_NUMBER) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSAND_SEPARATOR);
    }

    const millionFraction = Math.round(value / 10000) / 100;

    return millionFraction.toString().concat(this.MILLION_LETTER);
  }
}
