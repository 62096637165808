import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LandingComponent } from './landing/landing.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: 'auth',
    loadChildren: 'app/auth/auth.module#AuthModule'
  },
  {
    path: 'dashboard',
    loadChildren: 'app/containers/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'payments',
    loadChildren: 'app/containers/payments/payments.module#PaymentsModule'
  },
  {
    path: 'emailRSVPResponse',
    loadChildren: 'app/containers/rsvp-response/rsvp-response.module#RsvpResponseModule'
  },
  {
    path: 'settings',
    loadChildren: 'app/containers/settings/settings.module#SettingsModule'
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: []
})
export class AppRoutingModule {}
