import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { buildQueryFn } from '../../shared/helpers';
import { AngularFirestore } from '@angular/fire/firestore';
import { FilterAction } from '../../store/firestore/firestore.actions';
import { UserFirestoreReduxListener } from '@store/users/user.firestore.redux.listener';
import { ShowFirestoreReduxListener } from '@store/shows/show.firestore.redux.listener';
import { RegistrantFirestoreReduxListener } from '@store/registrants/registrant.firestore.redux.listener';
import { CompanyFirestoreReduxListener } from '@store/companies/company.firestore.redux.listener';
import { TicketFirestoreReduxListener } from '@store/tickets/tickets.firestore.redux.listener';
import { FirestoreReduxListener } from '@store/firestore/firestore.redux.listener';
import { BaseModel } from '@core/models/basemodel';
import { FirestoreReducerDataState } from '@store/firestore/firestore.reducer';
import { PlanSubscriptionFirestoreReduxListener } from '@store/plan-subscription/plan-subscription.firestore.redux.listener';
import { RegistrantEventMailListFirestoreReduxListener } from '@store/registrants/registrant-event-mail-list.firestore-subcollection.redux-listener';
import { RegistrantEventMailStatusFirestoreReduxListener } from '@store/registrants/registrant-event-mail-status.firestore-subcollection.redux-listener';
import { PlanFirestoreReduxListener } from '@store/plans/plan.firestose.redux.listener';
import { PublicInfoFirestoreReduxListener } from '@store/public-info/public-info.redux.listener';

@Injectable({
  providedIn: 'root'
})

/** Service used to create Firestore Redux Listeners and start listening to events Firestore events */

/**This functions must be called when we have the needed data to query fierstore collections.
 * e.g When the user is logged in we can start listening to Events collection changes from my companyId  */
export class FirestoreReduxService {
  constructor(private db: AngularFirestore, private store: Store<any>) {}

  private allReduxListeners: {
    [id: string]: FirestoreReduxListener<BaseModel, FirestoreReducerDataState<BaseModel>>;
  } = {};

  buildUserByCompanyFirestoreReduxListenerId(companyId: string): string {
    return `users_by_company_${companyId}`;
  }

  startUserFirestoreReduxListener(operations: FilterAction[], companyId: string): UserFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const userFirestoreReduxListener = new UserFirestoreReduxListener(this.db, this.store, queryFn);

    const id = this.buildUserByCompanyFirestoreReduxListenerId(companyId);
    this.allReduxListeners[id] = userFirestoreReduxListener;

    return userFirestoreReduxListener;
  }

  stopUserFirestoreReduxListener(companyId: string) {
    const id = this.buildUserByCompanyFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  buildShowByCompanyFirestoreReduxListenerId(companyId: string): string {
    return `shows_by_company_${companyId}`;
  }

  startShowFirestoreReduxListener(operations: FilterAction[], companyId: string): ShowFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const showFirestoreReduxListener = new ShowFirestoreReduxListener(this.db, this.store, queryFn);

    const id = this.buildShowByCompanyFirestoreReduxListenerId(companyId);
    this.allReduxListeners[id] = showFirestoreReduxListener;

    return showFirestoreReduxListener;
  }

  stopShowFirestoreReduxListener(companyId: string) {
    const id = this.buildShowByCompanyFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  buildRegistrantByShowFirestoreReduxListenerId(showId: string): string {
    return `registrants_by_show_${showId}`;
  }

  startRegistrantFirestoreReduxListener(operations: FilterAction[], showId: string): RegistrantFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const registrantFirestoreReduxListener = new RegistrantFirestoreReduxListener(this.db, this.store, queryFn);

    const id = this.buildRegistrantByShowFirestoreReduxListenerId(showId);
    this.allReduxListeners[id] = registrantFirestoreReduxListener;

    return registrantFirestoreReduxListener;
  }

  stopRegistrantFirestoreReduxListener(showId: string) {
    const id = this.buildRegistrantByShowFirestoreReduxListenerId(showId);
    this.unsubscribeListener(id);
    //Used to unsubscribe from email and email list events
    this.unsubscribeAllListenersBeginningWith(`registrants/`);
  }

  buildCompanyByCompanyIdFirestoreReduxListenerId(companyId: string): string {
    return `company_by_companyId_${companyId}`;
  }

  startCompanyFirestoreReduxListener(operations: FilterAction[], companyId: string): CompanyFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const companyFirestoreReduxListener = new CompanyFirestoreReduxListener(this.db, this.store, queryFn);

    const id = this.buildCompanyByCompanyIdFirestoreReduxListenerId(companyId);
    this.allReduxListeners[id] = companyFirestoreReduxListener;

    return companyFirestoreReduxListener;
  }

  stopCompanyFirestoreReduxListener(companyId: string) {
    const id = this.buildCompanyByCompanyIdFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  buildTicketByShowFirestoreReduxListenerId(showId: string): string {
    return `tickets_by_show_${showId}`;
  }

  startTicketFirestoreReduxListener(operations: FilterAction[], showId: string): TicketFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const ticketFirestoreReduxListener = new TicketFirestoreReduxListener(this.db, this.store, queryFn);

    const id = this.buildTicketByShowFirestoreReduxListenerId(showId);
    this.allReduxListeners[id] = ticketFirestoreReduxListener;

    return ticketFirestoreReduxListener;
  }

  stopTicketFirestoreReduxListener(showId: string) {
    const id = this.buildTicketByShowFirestoreReduxListenerId(showId);
    this.unsubscribeListener(id);
  }

  buildSubscriptionByCompanyFirestoreReduxListenerId(companyId: string): string {
    return `subscriptioin_by_company_${companyId}`;
  }

  startSubscriptionFirestoreReduxListener(
    operations: FilterAction[],
    companyId: string
  ): PlanSubscriptionFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const subscriptionFirestoreReduxListener = new PlanSubscriptionFirestoreReduxListener(this.db, this.store, queryFn);

    const id = this.buildSubscriptionByCompanyFirestoreReduxListenerId(companyId);
    this.allReduxListeners[id] = subscriptionFirestoreReduxListener;

    return subscriptionFirestoreReduxListener;
  }

  stopSubscriptionFirestoreReduxListener(companyId: string) {
    const id = this.buildSubscriptionByCompanyFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  startPlanFirestoreReduxListener(operations: FilterAction[]): PlanFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const planFirestoreReduxListener = new PlanFirestoreReduxListener(this.db, this.store, queryFn);

    this.allReduxListeners['plans'] = planFirestoreReduxListener;

    return planFirestoreReduxListener;
  }

  startRegistrantEventMailListFirestoreReduxListener(
    ancestorsId: string,
    operations: FilterAction[]
  ): RegistrantEventMailListFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const registrantEventMailListFirestoreReduxListener = new RegistrantEventMailListFirestoreReduxListener(
      this.db,
      this.store,
      { ancestorsId, queryFn }
    );

    this.allReduxListeners[ancestorsId] = registrantEventMailListFirestoreReduxListener;

    return registrantEventMailListFirestoreReduxListener;
  }

  startRegistrantEventMailStatusFirestoreReduxListener(
    ancestorsId: string,
    operations: FilterAction[]
  ): RegistrantEventMailStatusFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const registrantEventMailStatusFirestoreReduxListener = new RegistrantEventMailStatusFirestoreReduxListener(
      this.db,
      this.store,
      { ancestorsId, queryFn }
    );

    this.allReduxListeners[ancestorsId] = registrantEventMailStatusFirestoreReduxListener;

    return registrantEventMailStatusFirestoreReduxListener;
  }

  buildPublicInfoByShowFirestoreReduxListenerId(showId: string): string {
    return `public_info_by_show_${showId}`;
  }

  startPublicInfoByShowFirestoreReduxListener(
    operations: FilterAction[],
    showId: string
  ): PublicInfoFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const publicInfoFirestoreReduxListener = new PublicInfoFirestoreReduxListener(this.db, this.store, queryFn);

    const id = this.buildPublicInfoByShowFirestoreReduxListenerId(showId);

    this.allReduxListeners[id] = publicInfoFirestoreReduxListener;

    return publicInfoFirestoreReduxListener;
  }

  stopPublicInfoByShowFirestoreReduxListener(showId: string) {
    const id = this.buildPublicInfoByShowFirestoreReduxListenerId(showId);
    this.unsubscribeListener(id);
  }

  buildPublicInfoByCompanyFirestoreReduxListenerId(companyId: string): string {
    return `public_info_by_company_${companyId}`;
  }

  startPublicInfoByCompanyFirestoreReduxListener(
    operations: FilterAction[],
    companyId: string
  ): PublicInfoFirestoreReduxListener {
    const queryFn = buildQueryFn(operations);
    const publicInfoFirestoreReduxListener = new PublicInfoFirestoreReduxListener(this.db, this.store, queryFn);

    const id = this.buildPublicInfoByCompanyFirestoreReduxListenerId(companyId);

    this.allReduxListeners[id] = publicInfoFirestoreReduxListener;

    return publicInfoFirestoreReduxListener;
  }

  stopPublicInfoByCompanyFirestoreReduxListener(companyId: string) {
    const id = this.buildPublicInfoByCompanyFirestoreReduxListenerId(companyId);
    this.unsubscribeListener(id);
  }

  public existsListener(id: string) {
    return !!this.allReduxListeners[id];
  }

  public unsubscribeListener(id: string) {
    const listener = this.allReduxListeners[id];
    if (listener) {
      listener.stopListening();
      delete this.allReduxListeners[id];
    } else {
      console.log(`Listener with id:`, id, ' does not exist');
    }
  }

  public unsubscribeAllListenersBeginningWith(id: string) {
    for (const listenerId in this.allReduxListeners) {
      if (this.allReduxListeners.hasOwnProperty(listenerId)) {
        if (listenerId.startsWith(id)) {
          this.unsubscribeListener(listenerId);
        }
      }
    }
  }

  public unsubscribeAll(): void {
    for (const listenerId in this.allReduxListeners) {
      if (this.allReduxListeners.hasOwnProperty(listenerId)) {
        const listener = this.allReduxListeners[listenerId];
        listener.stopListening();
        this.allReduxListeners = {};
      }
    }
  }
}
