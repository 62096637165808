import { BaseModel } from '@models/basemodel';
import { FirestoreAction, AddedDataAction, ModifiedDataAction, DeletedDataAction } from './firestore.actions';
import { Store } from '@ngrx/store';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { FirestoreReducerDataState, FirestoreReducer } from './firestore.reducer';
import { Subscription } from 'rxjs';
import { FirestoreCollectionReference } from '@core/models/firestore-collection-reference';

/**Names used by firestore to publish their actions */

/**------------------------------------------ */
const FIRESTORE_ADDED_ACTION = 'added';
const FIRESTORE_REMOVED_ACTION = 'removed';
const FIRESTORE_MODIFIED_ACTION = 'modified';
/**------------------------------------------ */

/**Abstract class in charge of handling Firestore Redux Actions by listening to them and dispatch our own actions*/
export abstract class FirestoreReduxListener<T extends BaseModel, DataState extends FirestoreReducerDataState<T>> {
  protected collection: AngularFirestoreCollection;
  protected listeningSubscription: Subscription;
  //Only used for subcollections
  protected ancestorsId = '';

  constructor(
    protected db: AngularFirestore,
    protected store: Store<DataState>,
    protected firebaseReducer: FirestoreReducer<T, DataState>
  ) {}

  /**Listens to Firebase Redux changes and dispatches our own actions to be handled internally */
  public startListening(): Subscription {
    this.listeningSubscription = this.collection
      .stateChanges([FIRESTORE_ADDED_ACTION, FIRESTORE_REMOVED_ACTION, FIRESTORE_MODIFIED_ACTION])
      .subscribe(
        documentChangeActions => {
          documentChangeActions.forEach(actualDocumentChangeAction => {
            const doc = actualDocumentChangeAction.payload.doc;
            const id = doc.id;
            const data = doc.data();
            let actionToDispatch: FirestoreAction;

            switch (actualDocumentChangeAction.type) {
              case FIRESTORE_MODIFIED_ACTION:
                actionToDispatch = new ModifiedDataAction(
                  this.firebaseReducer.MODIFIED_ACTION,
                  id,
                  data,
                  this.ancestorsId
                );
                break;
              case FIRESTORE_REMOVED_ACTION:
                actionToDispatch = new DeletedDataAction(
                  this.firebaseReducer.DELETED_ACTION,
                  id,
                  data,
                  this.ancestorsId
                );
                break;
              default:
                // case FIRESTORE_ADDED_ACTION
                actionToDispatch = new AddedDataAction(this.firebaseReducer.ADDED_ACTION, id, data, this.ancestorsId);
            }
            this.store.dispatch(actionToDispatch);
          });
        },
        error => {
          console.log(`Error in ${this.firebaseReducer.collectionName}`, error);
        }
      );

    return this.listeningSubscription;
  }

  public stopListening(): void {
    if (this.listeningSubscription) {
      this.listeningSubscription.unsubscribe();
      this.listeningSubscription = undefined;
    }
  }

  protected initializeCollectionWithQuery(firestoreCollectionReference: FirestoreCollectionReference) {
    this.ancestorsId = firestoreCollectionReference.ancestorsId;
    this.collection = this.firebaseReducer.getCollectionReference(this.db, firestoreCollectionReference);
  }
}
