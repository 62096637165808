import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { DashboardActionsType } from './dashboard.actions.name';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';
import { Action, Store, select, MemoizedSelector } from '@ngrx/store';
import { AppState } from '..';
import {
  DashboardShowsChanged,
  DashboardShowsSearch,
  DashboardShowsFilter,
  FILTER_UPCOMING,
  FILTER_PAST
} from './dashboard.actions';
import { ShowReducer } from '@store/shows/show.firestore.reducer';
import { Show } from '@core/models/show';
import { dashboardDataState } from '.';

@Injectable()
export class DashboardEffects {
  private showReducer: ShowReducer;
  private actualFilter: MemoizedSelector<object, Show[]>;

  constructor(private actions$: Actions, private store: Store<AppState>) {
    this.showReducer = ShowReducer.getInstance();
    this.actualFilter = this.showReducer.getConvertToArraySelector();
  }

  @Effect()
  showSearch$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.SHOWS_SEARCH),
    switchMap((action: DashboardShowsSearch) => {
      const text = action.search;
      let showsSelector = this.showReducer.getShowsByTextSelector(action.search, this.actualFilter);
      if (text.trim().length === 0) {
        showsSelector = this.actualFilter;
      }

      const showsAction = this.store.pipe(select(showsSelector)).pipe(map(shows => new DashboardShowsChanged(shows)));
      return showsAction;
    })
  );

  @Effect()
  showFilter$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionsType.SHOWS_FILTER),
    withLatestFrom(this.store.pipe(select(dashboardDataState))),
    map(([action, { search }]) => {
      const filter = (action as DashboardShowsFilter).filter;
      switch (filter) {
        case FILTER_UPCOMING:
          this.actualFilter = this.showReducer.getUpcomingShowsSelector();
          break;
        case FILTER_PAST:
          this.actualFilter = this.showReducer.getPastShowsSelector();
          break;
        default:
          this.actualFilter = this.showReducer.getConvertToArraySelector();
          break;
      }

      return new DashboardShowsSearch(search);
    })
  );
}
