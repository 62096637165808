import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';

import { AuthComponent } from 'app/auth/auth.component';
import { TranslateService } from '@ngx-translate/core';
import { SidenavDataState } from '@store/sidenav/sidenav.reducer';
import { ShowSidenav, ToggleToolbar } from '@store/sidenav/sidenav.actions';

const dialogWidth = '34em';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  constructor(public dialog: MatDialog, private translate: TranslateService, private store: Store<SidenavDataState>) {}

  ngOnInit(): void {
    this.translate.use('en');
    this.store.dispatch(new ShowSidenav(false));
    this.store.dispatch(new ToggleToolbar(false));
  }

  openDialog(flag: string): void {
    const isLogIn = flag === 'logIn';
    const isSignUp = flag === 'signUp';

    this.dialog.open(AuthComponent, {
      panelClass: 'wrapper-auth',
      width: dialogWidth,
      data: { isLogIn, isSignUp }
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ShowSidenav(true));
    this.store.dispatch(new ToggleToolbar(true));
  }
}
