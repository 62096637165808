import { Component, EventEmitter, Output } from '@angular/core';
import * as XLSX from 'xlsx';

type AOA = any[][];

import { FileData } from '@core/types/file-data';
import { Dictionary } from '@core/types/dictionary.interface';
import { CurrencyPipe } from '@angular/common';

const listFileTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'text/csv'
];
const listFileExts = ['csv', 'xls', 'xlsx'];

@Component({
  selector: 'app-file-list-upload',
  templateUrl: './file-list-upload.component.html',
  styleUrls: ['./file-list-upload.component.scss']
})
export class FileListUploadComponent {
  wrongFile: boolean;
  loadingFile: boolean;
  fileUploaded: boolean;
  dragOver: boolean;
  totalElements: number;
  name: string;
  fileExt: string;

  @Output()
  sendList = new EventEmitter<FileData>();

  onDrop(evt: DragEvent) {
    this.dragOver = false;
    evt.preventDefault();
    const file = evt.dataTransfer.files[0];
    this.processFile(file);
  }

  onDragOver(evt: DragEvent) {
    this.dragOver = true;
    evt.preventDefault();
    evt.stopPropagation();
  }

  onDragLeave(evt: DragEvent) {
    this.dragOver = false;
    evt.preventDefault();
    evt.stopPropagation();
  }

  private checkFile(file: File): boolean {
    let isFileTypeValid: boolean;
    const splitted = file.name.split('.');
    const fileExt = splitted[splitted.length - 1].trim();
    this.fileExt = fileExt;

    if (file.type) {
      isFileTypeValid = listFileTypes.includes(file.type);
    } else {
      isFileTypeValid = listFileExts.includes(fileExt);
    }

    return isFileTypeValid;
  }

  private processFile(file: File) {
    const isValidFile = this.checkFile(file);

    if (!isValidFile) {
      this.wrongFile = true;
      return;
    } else {
      this.wrongFile = false;
    }

    this.loadingFile = true;

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      // read workbook
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      // grab first sheet
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      // save data
      const data: any[] = <AOA>XLSX.utils.sheet_to_json(ws, { header: 0 });

      const { name } = file;
      const fileData: FileData = { name, data };

      this.sendList.emit(fileData);

      this.name = name;
      this.totalElements = data.length;
      this.fileUploaded = true;
      this.loadingFile = false;
    };
    reader.readAsBinaryString(file);
  }

  get fileElements(): Dictionary {
    const size = this.totalElements || 0;
    const sizeParsed = new CurrencyPipe('en').transform(size, ' ', 'symbol', '1.0');
    return { size: sizeParsed };
  }

  get fileName(): Dictionary {
    const name = this.name || '';
    return { name };
  }

  get cntClasses(): Dictionary {
    return { 'on-hover': this.dragOver };
  }

  tryUploadFile(): void {
    const iptElement = document.getElementById('iptFile');
    if (iptElement) {
      iptElement.click();
    }
  }

  onFileChange($event): void {
    const { target } = $event;
    const file = target.files[0];

    if (!file) {
      return;
    }

    this.processFile(file);

    setTimeout(() => {
      const form = document.getElementById('fileForm') as HTMLFormElement;
      form.reset();
    }, 1000);
  }
}
