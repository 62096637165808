import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { Dictionary } from '@core/types/dictionary.interface';
import { CustomValidationErrors } from '@core/types/custom-validation-errors.interface';
import { SidenavDataState } from '@store/sidenav/sidenav.reducer';
import { sidenavDataState } from '@store/sidenav';

@Component({
  selector: 'app-form-ipt',
  templateUrl: './form-ipt.component.html',
  styleUrls: ['./form-ipt.component.scss']
})
export class FormIptComponent implements OnInit {
  @Input()
  theme = '';
  @Input()
  myForm: FormGroup;
  @Input()
  iptType = 'text';
  @Input()
  label: string;
  @Input()
  placeholder: string;
  // @Input()
  // disabled = false;
  @Input()
  formCtrlName: string;

  isVisible: boolean;

  lang$: Observable<string>;

  constructor(private readonly translate: TranslateService, private readonly store: Store<SidenavDataState>) {
    this.translate.setDefaultLang('en');

    this.store.pipe(select(sidenavDataState)).subscribe(({ currentLang }) => {
      this.lang$ = of(currentLang);
    });
  }

  ngOnInit() {
    this.isVisible = false;
    this.lang$.subscribe(lang => this.translate.use(lang));
  }

  get formCtrl(): AbstractControl {
    return this.myForm.get(this.formCtrlName);
  }

  get errors(): CustomValidationErrors {
    return { name: this.formCtrlName, errors: this.formCtrl.errors };
  }

  get cntIptClasses(): Dictionary {
    return { error: this.iptHasErrors, success: this.isIptValid };
  }

  get iptHasErrors(): boolean {
    const thereAreErrors = this.formCtrl.errors ? Object.keys(this.formCtrl.errors).length : false;
    return thereAreErrors && this.formCtrl.touched;
  }

  get isIptValid(): boolean {
    return this.formCtrl.valid && this.formCtrl.touched;
  }
}
