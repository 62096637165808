import { Show } from '../../core/models/show';
import { DashboardAction } from './dashboard.actions';
import { DashboardActionsType } from './dashboard.actions.name';

export interface DashboardDataState {
  shows: Show[];
  search: string;
  revenues: number;
  attendees: number;
  pageIndex: number;
  registrants: number;
  selectedTabIndex: number;
  loading: boolean;
}

export const initialState: DashboardDataState = {
  shows: [],
  search: '',
  revenues: 0,
  attendees: 0,
  pageIndex: 0,
  registrants: 0,
  selectedTabIndex: 0,
  loading: true,
};

export function dashboardDataReducer(state = initialState, action: DashboardAction): DashboardDataState {
  switch (action.type) {
    case DashboardActionsType.SHOWS_UPDATED:
      const shows = action.shows;
      return { ...state, shows, loading: false };
    case DashboardActionsType.ATTENDEES_UPDATED:
      const attendees = action.attendees;
      return { ...state, attendees };
    case DashboardActionsType.REVENUES_UPDATED:
      const revenues = action.revenues;
      return { ...state, revenues };
    case DashboardActionsType.REGISTRANTS_UPDATED:
      const registrants = action.registrants;
      return { ...state, registrants };
    case DashboardActionsType.SHOWS_FILTER:
      const selectedTabIndex = action.filter;
      return { ...state, selectedTabIndex, loading: true };
    case DashboardActionsType.SHOWS_SEARCH:
      const search = action.search;
      return { ...state, search, loading: true };
    case DashboardActionsType.SHOWS_PAGE_CHANGED:
      const pageIndex = action.pageIndex;
      return { ...state, pageIndex };
    default:
      return state;
  }
}
